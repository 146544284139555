<template>
  <div
    style="text-align: center; padding:3px;"
    :class="{
      'deviation-cell-high': deviation < 0,
      'deviation-cell-normal': deviation === 0,
      'deviation-cell-low': deviation > 0,
    }"
  >
    <b>{{ deviation }}</b>
  </div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {variables} from "@/componentsCompany/smz/variables";
import {mapGetters} from "vuex";
export default {
  name: 'element-point-employee-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},

  computed: {
    deviation() {
      if( !this.scope.row.scale || !this.scope.row.scale.point_chief || !this.scope.row.scale.point_employee ){
        return null;
      }
      return this.scope.row.scale.point_chief - this.scope.row.scale.point_employee;
    },
  },
  data() {
    return {
      scaleTypesEnum: variables.scaleTypesEnum,
    }
  },
  methods: {

  }
}

</script>

<style lang="scss">
@import "@/assets/css/project-variables";
.deviation-cell-high {
  background-color: #ffe3e3 !important;
}
.deviation-cell-normal {
  background-color: #fafafa !important;
}
.deviation-cell-low {
  background-color: #e8ffe0 !important;
}
</style>
